<script>
  import IMask from 'imask/esm/imask'

  /**
   * An credit card expiration date input.
   */
  export default {
    model: {
      prop: 'value',
      event: 'input',
    },
    props: {
      /** The input’s value. */
      value: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        expirationDateMaskBlocks: {
          MM: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
          },
          YY: {
            mask: IMask.MaskedRange,
            from: parseInt(new Date().getFullYear().toString().substring(2)),
            to: 99,
          },
        },
      }
    },
    computed: {
      month() {
        return this.value.split('/')[0]
      },
      year() {
        return this.value.split('/')[1]
      },
    },
  }
</script>

<template>
  <BaseInput
    type="tel"
    mask="MM/YY"
    :mask-blocks="expirationDateMaskBlocks"
    :min-length="5"
    :max-length="5"
    :multi-line-label="Boolean(!value)"
    :custom-messages="{ length: 'Please enter a valid expiration date.' }"
    data-cy="expiration"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
  >
    <!-- @slot The input’s label. Should contain text. Defaults to “Expiration (MM/YY)”. -->
    <slot> Expiration<br /><span v-if="!value">(MM/YY)</span> </slot>
    <template v-slot:icons>
      <!-- @slot Optional. Icon(s) to display on the right end of the input. Should contain one or multiple `BaseIconInput` component(s). -->
      <slot name="icons" />
    </template>
  </BaseInput>
</template>
